.pagination,
.content .pagination {
  @pagination-main-color:         @link-color;
  @pagination-align:              center; // left, center, right
  @pagination-margin-bottom:      @paragraph-margin-bottom * 2;
  @pagination-font-size:          18px;
  @pagination-font-weight:        500;
  @pagination-text-color:         #000;
  @pagination-control-size:       50px; // размер одного пункта (квадрат)


  margin-bottom: @pagination-margin-bottom;
  display: inline-flex;
  align-items: center;
  margin-left: 0;
  padding-left: 0;

  &-wrapper {
    text-align: @pagination-align;
  }

  & li {
    width: @pagination-control-size;
    height: @pagination-control-size;
    margin-right: 10px;
    margin-bottom: 0;
    padding-left: 0;

    &::before { display: none !important; }

    &:last-child {
      margin-right: 0;
    }
  }

  & a,
  & span {
    display: block;
    width: 100%;
    height: 100%;
    color: @pagination-text-color;
    font-size: @pagination-font-size;
    font-weight: @pagination-font-weight;
    text-decoration: none;
    text-align: center;
    line-height: @pagination-control-size;
    border-bottom: 3px solid @pagination-main-color;
    background: transparent;
    transition: 0.15s background-color ease-in;

    &:visited {
      color: @pagination-text-color;
    }

    &:hover {
      background-color: @pagination-main-color;
      border-bottom-color: @pagination-main-color;
      color: #fff;
    }

    &.active {
      background-color: @pagination-main-color;
      color: #fff;
      cursor: default;
    }
    &.disabled {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
      border-color: #ddd;
      & .icon {
        fill: #777;
      }
    }
  }


  & .pagination__prev,
  & .pagination__next {
    border-bottom: 0;

    .icon {
      width: @pagination-control-size;
      height: @pagination-control-size;
      fill: @pagination-main-color;
      transition: 0.15s fill ease-in;
    }

    &:hover {
      background-color: transparent;
      color: inherit;
    }
  }


}


.pagination__prev {
  & .icon {
    transform: rotate(-180deg);
  }
}

@media (max-width:@screen-md) {
  .content .pagination,
  .pagination {
    flex-wrap: wrap;
    margin-bottom: 20px;

    & li {
      margin-bottom: 20px;
    }
  }
}
