.slider-simple-wrapper {
  position: relative;
  margin-bottom: @paragraph-margin-bottom * 2;
}
.slider-simple {
  width: 900px;
  height: 566px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.slider-simple-item {
  & img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
.slider-simple__button-prev,
.slider-simple__button-next {
  position: absolute;
  z-index: 5;
  top: 50%;
  border: 0;
  background-color: transparent;
  padding: 0;
  transform: translateY(-50%);

  &:focus { outline: 0; }

  &:hover {
    & .icon {
      fill: @primary-color;
    }
  }

  & .icon {
    fill: #0c4733;
    transition: 0.2s fill ease;
  }
}
.slider-simple__button-prev {
  left: 0;
  & .icon {
    transform: rotate(90deg);
  }
}
.slider-simple__button-next {
  right: 0;
  & .icon {
    transform: rotate(-90deg);
  }
}

@media (max-width: @screen-md) {
  .slider-simple {
    width: 710px;
    height: 447px;
  }
}
@media (max-width: @screen-sm) {
  .slider-simple {
    width: auto;
    height: auto;
    max-height: 458px;
  }
}
