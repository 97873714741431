.block-wrapper {
  /**
  * универсальная обертка для блока
  */
  margin-bottom: 40px;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

.no-padding {
  padding: 0 !important;
  @media only screen and (min-width: 768px) {
    padding: 0 !important; 
  }
}

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
  @media only screen and (min-width: 768px) {
    margin-left: 0 !important; 
    margin-right: 0 !important; 
  }
}

.underline {
  text-decoration: underline;
}

.text-align-center {
  text-align: center;
}
