/**
* wm buttons
*/
// класс для сброса стилей браузера и создания общих стилей для кнопок
.btn,
.content .btn, {
  @button-padding-top:                11px;
  @button-padding-bottom:             11px;
  @button-padding-left:               10px;
  @button-padding-right:              10px;

  @button-border-width:               2px;
  @button-border-style:               solid;
  @button-border-radius:              3px;

  @button-font-size:                  16px;
  @button-font-weight:                400;

  @button-transition-duration:        0.1s;


  //## small button
  @button-sm-padding:                 4px 7px;
  @button-sm-border-radius:           0;
  @button-sm-font-size:               14px;


  //## button primary
  @button-primary-color:              #fff;
  @button-primary-bg:                 #e54d30;
  @button-primary-border:             #e54d30;

  @button-primary-color-hover:        #e54d30;
  @button-primary-bg-hover:           #fff;
  @button-primary-border-hover:       #e54d30;


  //## button secondary
  @button-secondary-color:              #fff;
  @button-secondary-bg:                 @primary-color;
  @button-secondary-border:             @primary-color;

  @button-secondary-color-hover:        #fff;
  @button-secondary-bg-hover:           lighten(@primary-color, 10);
  @button-secondary-border-hover:       lighten(@primary-color, 10);


  //## button transparent
  @button-transparent-color:          #fff;
  @button-transparent-border:         #fff;

  @button-transparent-color-hover:    #fff;
  @button-transparent-bg-hover:       #e54d30;
  @button-transparent-border-hover:   #e54d30;


  //## button disabled
  @button-disabled-bg-color:          #bcbcbc;
  @button-disabled-border-color:      #bcbcbc;
  @button-disabled-color:             #7f7f7f;


  //## button link
  @button-link-text-decoration:    none;

  @button-link-color:              @link-color;
  @button-link-bg:                 transparent;
  @button-link-border:             transparent;

  @button-link-color-hover:        @link-hover;
  @button-link-bg-hover:           transparent;
  @button-link-border-hover:       transparent;



  display: inline-block;

  padding-top: @button-padding-top;
  padding-bottom: @button-padding-bottom;
  padding-left: @button-padding-left;
  padding-right: @button-padding-right;

  border-width: @button-border-width;
  border-style: @button-border-style;
  border-radius: @button-border-radius;

  font-size: @button-font-size;
  font-weight: @button-font-weight;
  line-height: 1.1;
  text-decoration: none;

  cursor: pointer;

  transition-duration: @button-transition-duration;
  transition-timing-function: ease-in-out;
  transition-property: all;

  outline: none;

  // min-width: 210px;
  text-align: center;

  &--sm {
    padding: @button-sm-padding;
    border-radius: @button-sm-border-radius;
    font-size: @button-sm-font-size;
  }

  &--primary {
    border-color: @button-primary-border;
    background-color: @button-primary-bg;
    color: @button-primary-color;

    &:hover {
      border-color: @button-primary-border-hover;
      background-color: @button-primary-bg-hover;
      color: @button-primary-color-hover;
    }
    &.contacts__tab_item {
      &:active {
        border-color: @button-primary-bg;
        background-color: transparent;
        color: @button-primary-bg;
      }
      &:focus {
        border-color: @button-primary-bg;
        background-color: transparent;
        color: @button-primary-bg;
      }
      &.active {
        border-color: @button-primary-bg;
        background-color: transparent;
        color: @button-primary-bg;
      }
    }
  }


  &--secondary {
    border-color: @button-secondary-border;
    background-color: @button-secondary-bg;
    color: @button-secondary-color;

    &:hover {
      border-color: @button-secondary-border-hover;
      background-color: @button-secondary-bg-hover;
      color: @button-secondary-color-hover;
    }
    &:visited {
      color: @button-secondary-color;
    }
  }


  &--link {
    border-color: @button-link-border;
    background-color: @button-link-bg;
    color: @button-link-color;
    text-decoration: @button-link-text-decoration;

    &:hover {
      border-color: @button-link-border-hover;
      background-color: @button-link-bg-hover;
      color: @button-link-color-hover;
    }
    &:focus {
      border-color: @button-link-border-hover;
      background-color: @button-link-bg-hover;
      color: @button-link-color-hover;
    }
    &:visited {
      color: @button-link-color;
    }
  }


  &--transparent {
    border-color: @button-transparent-border;
    background-color: transparent;
    color: @button-transparent-color;

    
    &:visited {
      color: @button-transparent-color;
    }
    &.btn--primary {
      border-color: @button-primary-border;
      color: @button-primary-border;
    }
    &.btn--secondary {
      border-color: @button-secondary-border;
      color: @button-secondary-border;
    }
    &:hover {
      border-color: @button-transparent-border-hover;
      background-color: @button-transparent-border-hover;
      color: @button-transparent-color-hover;
    }
  }


  &--disabled,
  &[disabled] {
    pointer-events: none; // ссылка не работает
    cursor: not-allowed;
    box-shadow: none;
    background-color: @button-disabled-bg-color;
    border-color: @button-disabled-border-color;
    color: @button-disabled-color;

    &:hover {
      border-color: @button-disabled-bg-color;
      background-color: @button-disabled-border-color;
      color: @button-disabled-color;
    }
    &:visited {
      color: @button-disabled-color;
    }
  }

}


@media (max-width: 1024px) {
  .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: @screen-sm) { /* 768px по умолчанию */
  .btn,
  .content .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
}
