.select2 {
  //# сам блок
  @select2-block-height:                      32px;
  @select2-block-background-color:            #ececec;
  @select2-block-border-color:                1px solid #aaa;
  @select2-block-border-radius:               1px;
  @select2-block-text-color:                  #646464;

  //# список
  @select2-optins-item-padding:               6px; // влияет на размер одного пункта option
  @select2-options-background-color:          @primary-color; // при наведении на пункт
  @select2-options-text-color:                #fff;

  @select2-options-selected-background-color: #ddd; // выбранный пункт
  @select2-options-selected-text-color:       #646464;


  display: block;
  width: 100% !important;
  height: @select2-block-height;
  border-radius: @select2-block-border-radius;
  box-shadow: inset 0 0 24px rgba(0,0,0,0.05);
  &:focus {
    outline: 0;
  }

  &-container .select2-selection--single {
    height: @select2-block-height;

    background-color: @select2-block-background-color;
    box-shadow: none;
    border: @select2-block-border-color;
    border-radius: @select2-block-border-radius;
    &:focus {
      outline: 0;
    }
  }
  &-container--default .select2-selection--single .select2-selection__rendered {
    line-height: @select2-block-height;
    color: @select2-block-text-color;
  }
  &-container--default .select2-selection--single .select2-selection__arrow {
    height: @select2-block-height;
  }
  &-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: @select2-options-background-color;
    color: @select2-options-text-color;
  }
  &-container--default .select2-results__option[aria-selected=true] {
    background-color: @select2-options-selected-background-color;
    color: @select2-options-selected-text-color;
  }
  &-results__option {
    padding: @select2-optins-item-padding;
  }
}

#form-order, #form-question {

  & .select2 {
    
    height: 40px;

    @media (max-width: 579px) {
      height: 27px;
    }

  }

  & .select2-container .select2-selection--single {

    height: 40px;

    background-color: #fff;
    box-shadow: none;

    border-color: #e1e1e1;
    border-radius: 5px;

    @media (max-width: 579px) {
      height: 27px;
    }

  }

  & .select2-container--default .select2-selection--single .select2-selection__rendered {

    height: 40px;
    line-height: 40px;
    @media (max-width: 579px) {
      height: 27px;
      line-height: 27px;
    }

  }

  & .select2-container--default .select2-selection--single .select2-selection__arrow {

    height: 40px;
    @media (max-width: 579px) {
      height: 27px;
    }

  }

  & .select2-container--default .select2-selection--single .select2-selection__arrow b {
    
    position: relative;
    transform: translateX(-50%) translateY(-50%);

    display: block;

    border: none;
    width: 13px;
    height: 13px;

    margin-top: 0;

    &:before {

      position: absolute;
      top: 0;
      left: 0;
      
      content: '';
      width: 13px;
      height: 13px;
      background-image: url('../img/icons/down.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transition: all ease-in-out 0.1s;

    }

  }

  & .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {

    &:before {

      transform: rotate(180deg);

    }

  }

  & .select2-container--default.select2-container--open .select2-selection--single {

    border-color: @primary-color;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

  }

}

.site-form__dropdown {

  & .select2-results__option {
    
    color: #000;

    border-bottom: 1px solid #e1e1e1;

  }

  & .select2-results__option--highlighted[aria-selected] {
    
    color: #000;
    background-color: #fff;

  }

}
