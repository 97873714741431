/**
* modal form style
*
* Dependencies:
* 1. /src/components/wm.form/wm.form.less
* 2. /src/components/wm.buttons/wm.buttons.less
*/
.site-form-wrapper--modal {
  position: relative;
  background-color: #f4f4f4;
  max-width: 620px;
  margin: 15px auto;
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  @media (max-width: 579px) {
    margin: 5px auto;
  }

  & .site-form__textarea {
    resize: none;
  }
}
.modal-form-message-info-wrapper {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  visibility: visible;
  background-color: #fff;
  padding-left: 3.5%;
  padding-right: 3.5%;
  text-align: center;

  &.disabled {
    z-index: -1;
    visibility: hidden;
  }
}

.modal-form__message-info {
  width: 100%;
  text-align: center;
  font-size: 22px;
  color: @heading-color;
  margin-bottom: 40px;

  & > div {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modal-form__message-caption {
  color: @text-color;
}

#form-order, #form-callback, #form-question, #form-offer {

  & .site-form-element-wrapper {
    @media (max-width: 579px) {
      margin-bottom: 5px;
    }
  }

  & .site-form {
    @media (max-width: 579px) {
      padding-top: 15px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  & .form-order__col, & .form-callback__col {

    @media (max-width: 579px) {

      width: 100%;
      max-width: 100%;
      flex-basis: 100%;

    }

  }

  & .form-callback__col {

    margin-left: auto;
    margin-right: auto;

    padding-top: 0;
    padding-bottom: 10px;
    @media (max-width: 579px) {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
    & .site-form-element-wrapper {
      margin-bottom: 10px;
      @media (max-width: 579px) {
        margin-bottom: 5px;
      } 
    }

  }

  & .mfp-close {

    width: 58px;
    height: 58px;

    @media (max-width: 579px) {
      width: 36px;
    }
    
    & svg {

      width: 18px;
      height: 18px;

    }

  }

  & .site-form {

    padding-bottom: 14px;

    & button {
      
      font-family: @main-font;

      min-width: 160px;

      @media (max-width: 579px) {
        padding-top: 5px;
        padding-bottom: 5px;
      }

    }

    &__head {

      font-family: @secondary-font;
      font-size: 27px;
      color: #000;
      text-align: left;

      @media (max-width: 579px) {
        font-size: 22px;
        margin-bottom: 5px;
      }

    }

    &-element-wrapper {

      & .site-form__input {

        background-color: #fff;
        border-color: #e1e1e1;
        border-radius: 5px;
        @media (max-width: 579px) {
          height: 27px;
        }

      }

      & .site-form__textarea {

        background-color: #fff;
        border-color: #e1e1e1;
        border-radius: 5px;

        height: 75px;

        @media (max-width: 579px) {
          height: 50px;
          min-height: 50px;
          padding-top: 5px;
          padding-bottom: 5px;
        }

      }

      & .site-form__label {

        &.required {

          position: relative;

          display: inline-block;
          width: auto;
          
          &:after {

            position: absolute;
            top: -5px;
            right: -10px;

            content: '*';
            color: @primary-color-2;

          }

        }

      }

      &.attach {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 30px;
        @media (max-width: 579px) {
          margin-bottom: 5px;
        }
        & .caption {
          width: 100%;
          margin-bottom: 10px;
        }
        & input {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          width: 150px;
          padding: 0;
          cursor: pointer;
        }
        & label {
          position: relative;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          align-items: center;
          color: @primary-color;
          text-decoration: none;
          cursor: pointer;
          z-index: 1;
          @media (max-width: 579px) {
            font-size: 13px;
          }          
          
        }

        & svg {
          width: 14px;
          height: 13px;
          margin-right: 7px;
          cursor: pointer;
        }
        & .attach_monitor {
          min-width: 100px;
          @media (max-width: 579px) {
            font-size: 12px;
          }
          // margin-left: 15px;
        }
      }

    }

    &__input {

      font-family: @secondary-font;

    }

    &__textarea {

      font-family: @secondary-font;

    }

  }

  .form__checkbox-wrp {
    & label span {
      @media (max-width: 579px) {
        font-size: 13px;
        position: relative;
        top: -2px;
      }
    }
  }

}
