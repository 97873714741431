// @font-face {
//   font-family: "roboto";
//   src: url("../fonts/roboto/roboto-regular/roboto-regular.woff2?v=1.1.0") format("woff2"),
//   url("../fonts/roboto/roboto-regular/roboto-regular.woff?v=1.1.0") format("woff");
//   font-weight: 400;
//   font-style: normal;
// }
// @font-face {
//   font-family: "roboto";
//   src: url("../fonts/roboto/roboto-bold/roboto-bold.woff2") format("woff2"),
//   url("../fonts/roboto/roboto-bold/roboto-bold.woff") format("woff");
//   font-weight: 700;
//   font-style: normal;
// }

// PT Sans Caption

@font-face {
  font-family: "ptsans";
  src: local('PTSans_Caption-Regular'), url("../fonts/PTsans/PTSans_Caption-Regular.woff2") format("woff2"),
  url("../fonts/PTsans/PTSans_Caption-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ptsans";
  src: local('PTSans_Caption-Bold'), url("../fonts/PTsans/PTSans_Caption-Bold.woff2") format("woff2"),
  url("../fonts/PTsans/PTSans_Caption-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

// Ubuntu

@font-face {
  font-family: "ubuntu";
  src: local('Ubuntu-Regular'), url("../fonts/ubuntu/Ubuntu-Regular.woff2") format("woff2"),
  url("../fonts/ubuntu/Ubuntu-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  src: local('Ubuntu-Italic'), url("../fonts/ubuntu/Ubuntu-Italic.woff2") format("woff2"),
  url("../fonts/ubuntu/Ubuntu-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  src: local('Ubuntu-Bold'), url("../fonts/ubuntu/Ubuntu-Bold.woff2") format("woff2"),
  url("../fonts/ubuntu/Ubuntu-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  src: local('Ubuntu-BoldItalic'), url("../fonts/ubuntu/Ubuntu-BoldItalic.woff2") format("woff2"),
  url("../fonts/ubuntu/Ubuntu-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  src: local('Ubuntu-Medium'), url("../fonts/ubuntu/Ubuntu-Medium.woff2") format("woff2"),
  url("../fonts/ubuntu/Ubuntu-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  src: local('Ubuntu-MediumItalic'), url("../fonts/ubuntu/Ubuntu-MediumItalic.woff2") format("woff2"),
  url("../fonts/ubuntu/Ubuntu-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  src: local('Ubuntu-Light'), url("../fonts/ubuntu/Ubuntu-Light.woff2") format("woff2"),
  url("../fonts/ubuntu/Ubuntu-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ubuntu";
  src: local('Ubuntu-LightItalic'), url("../fonts/ubuntu/Ubuntu-LightItalic.woff2") format("woff2"),
  url("../fonts/ubuntu/Ubuntu-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

/**
* rouble font
*/
// @font-face {
//     font-family: 'rouble';
//     src: url('../fonts/rouble-webfont.woff') format('woff'),
//          url('../fonts/rouble-webfont.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }
